.page-container {
    width: 800px;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    background-color: #f9f9f9;
}

.title-container {
    margin-top: 10px;
    width: 100%;
    display: flex;
}

.json-button {
    margin-left: auto;
}

.form-container {
    width: 100%;
    margin-top: 5px;
}

.code-snippet {
    font-family: 'Courier New', monospace;
    background-color: #f1f1f1;
    padding: 2px 4px;
    border-radius: 4px;
}

.submit-button-container {
    margin-top: 50px;
}