/* .cardCol {
  display: inline-flex;
  align-self: stretch;
} */

.customCard {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}



.customCard .ant-card-body {
  flex: 1;
}

.customCard .ant-card-actions {
  bottom: 0;
}

.customCard .positive{
    color: #73d13d;
}

.customCard .negative{
    color: #ff4d4f
}

.customCard h3, .customCard h4 {
    padding: 0;
    padding-top: 3px;
    margin: 0;
}

.customCard p {
    padding: 0;
    padding-top: 3px;
    margin: 0;
    font-size: 12px;
}

.customCard .difference {
  margin-top: 10px;
  border: 1px solid rgb(177, 177, 183);
  /* border-collapse: collapse; */
  width: 100%;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  border-radius: 10px;
}

.customCard .rowsFiltering {
  margin-top: 10px;
  margin-bottom: 2px;
  /* border: 1px solid rgb(177, 177, 183); */
  /* border-collapse: collapse; */
  width: 100%;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
  font-size: 12px;
  border-collapse: separate;
  border-spacing: 0;
  /* border: 1px solid rgb(177, 177, 183); */
  border-radius: 10px; /* Adjust the value as needed */
}

.customCard .rowsFiltering .tableHeader {
  background-color: #ffffff;
  border-collapse: collapse;
  border: 1px solid #ffffff;

  /* text-align: left; */
}

.customCard .rowsFiltering .tableHeader th {
  font-weight: 400; 
  background-color: #ffffff;
  border-top-left-radius: 10px; 
  border-top: 1px solid rgb(177, 177, 183);
  border-left: 1px solid rgb(177, 177, 183)
}

.customCard .rowsFiltering .tableHeader td {
  font-weight: 400; 
  background-color: #ffffff;
  border-top-right-radius: 10px; 
  border-top: 1px solid rgb(177, 177, 183);
  border-right: 1px solid rgb(177, 177, 183)
}


.customCard .rowsFiltering .filteredOut {
  background-color: #ff7875;
  border-collapse: collapse;
  border: 1px solid #ff7875;

  /* text-align: left; */
}

.customCard .rowsFiltering .filteredOut th {
  font-weight: 400;
}

.customCard .rowsFiltering .outputRow {
  background-color: #95de64;
  /* border-collapse: collapse; */
  border: 1px solid #95de64;
  /* text-align: left; */
}

.customCard .rowsFiltering .outputRow th {
  font-weight: 400; 
}

.customCard .rowsFiltering .firstRow th{
  font-weight: bold; 
  background-color: #ffffff;
  border-top-left-radius: 10px; 
  border-top: 1px solid rgb(177, 177, 183);
  border-left: 1px solid rgb(177, 177, 183)
}

.customCard .rowsFiltering .firstRow td{
  font-weight: bold; 
  background-color: #ffffff;
  border-top-right-radius: 10px; 
  border-top: 1px solid rgb(177, 177, 183);
  border-right: 1px solid rgb(177, 177, 183)
}

.customCard .rowsFiltering .lastRow th{
  font-weight: 400; 
  background-color: #95de64;
  border-bottom-left-radius: 10px; 
}

.customCard .rowsFiltering .lastRow td{
  font-weight: 400; 
  background-color: #95de64;
  border-bottom-right-radius: 10px; 
}

.customCard .rowsFiltering .lastRowUnique th{
  font-weight: 400; 
  background-color: #ffffff;
  border-bottom-left-radius: 10px; 
  border-bottom: 1px solid rgb(177, 177, 183);
  border-left: 1px solid rgb(177, 177, 183)
}

.customCard .rowsFiltering .lastRowUnique td{
  font-weight: 400; 
  background-color: #ffffff;
  border-bottom-right-radius: 10px; 
  border-bottom: 1px solid rgb(177, 177, 183);
  border-right: 1px solid rgb(177, 177, 183)
}

/* .customCard .rowsFiltering .outputRows tr:last-child td:last-child input {
  border-collapse: separate;
  border-radius: 0 0 10px 10px;
} */

/* .customCard .rowsFiltering  .lastRow {

} */

.customCard .description {
  font-size: 12px;
  font-weight: 400;
}

.errorCard {
  text-align: center;

}